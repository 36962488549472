<template>
  <div>
    <v-dialog v-model="BookingDetails" width="600" persistent>


      <!-- <v-layout row wrap>
          <v-flex xs10>

          </v-flex>
          <v-flex xs2>

            <v-btn icon dark @click="close()">
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout> -->

      <!--date to picker dailog-->

      <!--end date picker dailog-->



      <!--date to picker dailog-->

      <!--end date picker dailog-->



      <v-stepper v-model="e1">
        <v-stepper-header>

          <v-stepper-step step="1">معلومات اليوزر</v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step v-if="day_to_open[0].every_day==0" step="2">تاريخ الحجز </v-stepper-step>
          <v-divider></v-divider>


          <!-- <v-stepper-step v-if="ItemsReservationRequirements.length>0" :complete="e1 > 1" step="3"> مرفقات الحجز
          </v-stepper-step> 


          v-divider></v-divider> -->

          <v-stepper-step v-if="ItemFeauterAvailable" :complete="e1 > 3" step="4"> المميزات</v-stepper-step>
          <v-divider v-if="pricepayment_when_receiving==0"></v-divider>


          <v-divider v-if="pricepayment_when_receiving==0 || ItemFeauterAvailable"></v-divider>

          <v-stepper-step v-if="pricepayment_when_receiving==0" :complete="e1 > 3" step="5">طريقه الدفع
          </v-stepper-step>



        </v-stepper-header>



        <v-stepper-items height="400px">
          <v-stepper-content step="0" height="400px">

            <v-form ref="valid_SelectItem" v-model="valid_SelectItem">
              <v-layout row wrap>
                <v-flex pa-3>
                  <v-lable>اختر اسم الحجز </v-lable>
                  <v-select background-color="#ffffff" height="50" :rules="ItemnameRules" class="my-2"
                    :items="Owner_items" item-value="id" item-text="item_name" v-model="owner_itemId"
                    @change="getitemsById(),xx=false,date=''" placeholder="اسم الحجز" required>
                  </v-select>
                </v-flex>
              </v-layout>

              <v-layout row wrap pr-4 pb-4>
                <v-flex xs3 pr-3>
                  <v-btn color="primary" @click="Select_Items()" style="position: relative;right: 3%;width: 103px;">


                    التالي
                  </v-btn>
                </v-flex>

                <v-flex xs3 pr-3>
                  <v-btn text color="red" style="color:#fff" @click="close()">خروج</v-btn>
                </v-flex>


              </v-layout>
            </v-form>


          </v-stepper-content>


          <!--Star Custumer Info-->
          <v-stepper-content step="1" height="400px">
            <v-form ref="valid_UserInfo" v-model="valid_UserInfo">



              <v-container fluid="">
                <v-layout row wrap pr-4>


                  <v-flex xs12 md12 sm12 pt-5>
                    <v-lable>الاسم </v-lable>
                    <v-text-field filled v-model="post_data.user.name" height="50" style="" placeholder="الاسم" required
                      :rules="UsernameRules"></v-text-field>
                  </v-flex>


                  <v-flex xs12 md12 sm12>
                    <v-lable>رقم الهاتف</v-lable>
                    <v-text-field filled v-model="post_data.user.phone" height="50" style="" placeholder="رقم الهاتف"
                      type="number" required :rules="phoneRules"></v-text-field>
                  </v-flex>

                </v-layout>

                <v-layout row wrap>
                  <v-flex xs3 pr-3>
                    <v-btn color="primary" @click="step1()" style="position: relative;right: 3%;width: 103px;">


                      التالي
                    </v-btn>
                  </v-flex>

                  <v-flex xs3 pr-3>
                    <v-btn text color="red" style="color:#fff" @click="close()">خروج</v-btn>
                  </v-flex>


                </v-layout>
              </v-container>
            </v-form>

          </v-stepper-content>
          <!--End Custumer Info-->


          <!--Reserverations Date-->
          <v-stepper-content step="2" height="400px">

            <v-dialog v-model="date_dialog" width="500" height="auto">
              <v-date-picker locale="ar-iq" :min="date_today()" year-icon="mdi-calendar-blank" scrollable
                prev-icon="mdi-skip-next" next-icon="mdi-skip-previous " :allowed-dates="allowedDates" color="#ff6600"
                v-model="date" class="mt-4" @change="select_date">

                <!-- <v-btn text color="primary" @click="menu = false">Cancel</v-btn> -->
                <v-btn color="primary" @click="date_dialog=false;get_times();time_av=true"
                  style="font-family: 'Roboto' !important;">اختيار</v-btn>
              </v-date-picker>
            </v-dialog>

            <v-form ref="form" v-model="valid_day">
              <v-container>



                <v-layout row wrap>
                  <v-flex pa-3>

                    <v-text-field outlined dense readonly v-if="owner_itemId || items.length==1"
                      style="background-color:#fff" placeholder="اختر تاريخ الحجز" append-icon="fas fa-calendar-day"
                      required :rules="DatenameRules" v-model="date" @click="date_dialog=true,xx=true"></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap v-if="day_to_open[0].every_day==1 ">

                </v-layout>

                <div v-if="time_av">
                  <p v-if="available_times.length==0">لاتوجد اوقات متاحه في هاذا اليوم</p>

                  <div v-else>

                    <span>اختر وقت الحجز</span>
                  </div>

                </div>
                <v-radio-group v-model="subs_time" required row :rules="TimeRules" v-if="xx">


                  <div v-for="time  in available_times" :key="time">
                    <p>
                      <span v-if="time.reservation_type_id==2">
                        <v-radio
                          :label="formatAMPM(time.sub_time[0].from_sub_time)+'  عدد الحجوزات المتبقيه '+count_availble_per(time.sub_time)"
                          :value="ava_sub(time.sub_time)">
                        </v-radio>
                      </span>

                      <span v-else-if="time.reservation_type_id==1" v-for="sub_time  in time.sub_time" :key="sub_time">

                        <v-radio v-if="sub_time.reservation.length" disabled
                          :label="formatAMPM(sub_time.from_sub_time)+' محجوز'" :value="sub_time">

                        </v-radio>

                        <v-radio v-else :label="formatAMPM(sub_time.from_sub_time)" :value="sub_time">

                        </v-radio>


                      </span>

                      <span v-else-if="time.reservation_type_id==3" v-for="sub_time  in time.sub_time" :key="sub_time">

                        <v-radio :label="formatAMPM(time.sub_time[0].from_sub_time)" :value="time.sub_time[0]">

                        </v-radio>

                      </span>

                    </p>

                  </div>
                </v-radio-group>

              </v-container>
            </v-form>



            <v-layout row wrap pb-4 pr-4>

              <!--
                 <v-flex xs3 pr-3
            
                v-if="pricepayment_when_receiving==0 || ItemFeauterAvailable || ItemsReservationRequirements.length>0">
                <v-btn color="primary" @click="step2()">
                  التالي
                </v-btn>
              </v-flex>
              -->
              <v-flex style="padding-right:15px;" xs2 v-if="pricepayment_when_receiving==0 || ItemFeauterAvailable "
                pr-4>
                <v-btn color="primary" @click="step2()">
                  التالي
                </v-btn>
              </v-flex>

              <v-flex xs2 v-else>
                <v-btn color="success" style="position: relative;right: 3%;width: 103px;" @click="booking_item()">
                  <span v-show="!loading">حجز</span>
                  <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>

                </v-btn>


              </v-flex>

              <v-flex xs2 style="padding-right:15px;">
                <v-btn color="success" style="position: relative;right: 3%;width: 103px;color:#fff" @click="e1 =1">
                  السابق
                </v-btn>
                <!-- <v-btn text @click="close()">خروج</v-btn> -->
              </v-flex>
            </v-layout>









          </v-stepper-content>
          <!--End Reserverations Date-->


          <!--ReservationRequirements-->
          <!-- <v-stepper-content step="3" >

            <p>مرفقات الحجز</p>
            <input type="file" style="display:none" ref="image" accept="image/*" @change="onFilePicked" />
            <v-layout row wrap>





              <v-flex xs3 v-for="(item,index) in ItemsReservationRequirements" :key="item" pr-3>
                <p>{{item.requirement_name}}</p>
                <span style="color:#fff;display:none">


                  {{img_name}} </span>
                <v-card height="180px" width="250px" class="card_img">

                  <v-img :src="images[index]" height="100%" width="100%" class="card_img" v-if="images[index]!='ab'">
                    <v-btn icon="" style="padding-right: 24px;position:relative;left: 46px;"
                      @click="delete_img(img_id,index)">

                      <v-icon color="#fff">fas fa-window-close</v-icon>
                    </v-btn>

                  </v-img>



                  <v-btn icon v-else @click='pickFile(item.id,index)'>
                    <v-icon style="margin:0px" size="40">fas fa-plus-square</v-icon>
                  </v-btn>
                </v-card>

              </v-flex>




            </v-layout>



    

            <v-layout row wrap>
              <v-flex xs3 pr-3 v-if="pricepayment_when_receiving==0 ">
                <v-btn color="primary" @click="stepForItemReq">
                  التالي
                </v-btn>
              </v-flex>

              <v-flex xs3 pr-3 v-else>

                <v-btn color="success" style=";width:130px" @click="check_re();booking_item()">
                  <span v-show="!loading">حجز</span>
                  <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>

                </v-btn>
              </v-flex>

              <v-flex xs3 pr-3>
                <v-btn style="position:relative;right:3%" color="primary" @click="e1 = 1"
                  v-if="day_to_open[0].every_day==0">
                  السابق
                </v-btn>

              </v-flex>
            </v-layout>





          </v-stepper-content> -->
          <!--End ReservationRequirements-->


          <!--Reservation ItemFeauters steppers -->
          <v-stepper-content step="4" style="    height: 300px !important" height="400px">

            <v-card pt-3 style="    padding-top: 10px;" flat>
              <span v-for="item in ItemFeauter" :key="item">

                <v-row align="center">
                  <v-checkbox v-model="post_data.item_features" hide-details :value="item" class="shrink mr-2 mt-0">
                  </v-checkbox>
                  <span style="padding-right:10px"> {{item.feature_name}}</span>
                  <v-spacer></v-spacer>
                  <v-chip style="margin-left:10px">IQD {{item.feature_price}} </v-chip>

                </v-row>


              </span>
            </v-card>
            <v-divider style="margin-top:20px;margin-bottom:20px"></v-divider>

            <v-layout row wrap pr-4 pb-4 style="padding-top:27%">
              <v-flex xs2 pr-3 v-if="pricepayment_when_receiving==0 ">
                <v-btn color="primary" @click="stepForItemFuter;e1=5" v-if="pricepayment_when_receiving==0 ">
                  التالي
                </v-btn>

              </v-flex>

              <v-flex xs2 v-else>

                <v-btn color="success" style=";width:130px" @click="booking_item()">
                  <span v-show="!loading">حجز</span>
                  <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>

                </v-btn>
              </v-flex>

              <v-flex xs2>
                <v-btn style="position:relative;right:3%;color:#fff" color="primary" @click="e1 = 2">
                  السابق
                </v-btn>


              </v-flex>
            </v-layout>



          </v-stepper-content>
          <!-- ItemFeauters steppers -->



          <!--Reservation Payment-->
          <v-stepper-content step="5">

            <v-form ref="form_fa" v-model="valid_pay">

              <!-- <v-radio-group v-model="bill">
                <v-radio label="انشاء فاتوره تسديد" :value="true"></v-radio>

                <v-radio label="الدفع نقدا" :value="false"></v-radio>
              </v-radio-group> -->

              <div v-show="bill">
                <v-divider></v-divider>
                <v-checkbox v-model="withoutBills" label="حجز بدون فاتوره"></v-checkbox>
                <!-- <v-card-title style="font-size:font-size: 18px;">
                  ويه تسديد صار تكدر تدفع فاتورتك وانت بالبيت من خلال رقم الفاتورة الي يوصلك برسالة ..
                  والتسديد من
                  خلال ادخال رقم بطاقة الدفع الكي كارد أوالماستر كارد
                </v-card-title> -->
                <v-divider></v-divider>


                <v-layout row wrap>
                  <v-flex pa-3>

                    <v-text-field outlined dense placeholder="ادخل رقم هاتفك لانشاء فاتوره" v-if="!withoutBills" append-icon="fas fa-phone"
                      type="number" :rules="numRules" required v-model="post_data.phone">
                    </v-text-field>
                  </v-flex>

                </v-layout>
                <v-col>

                </v-col>
              </div>

              <!-- <div v-else></div>
 -->


            </v-form>










            <v-layout row wrap pb-4 pr-4>
              <v-flex xs3 pr-3>
                <v-btn color="success" style=";width:130px" @click="booking_item()">
                  <span v-show="!loading">حجز</span>
                  <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>

                </v-btn>


              </v-flex>

              <!--
                 <v-flex xs3 pr-3 v-if="ItemsReservationRequirements.length>0">

                 <v-btn  style="position:relative;right:3%" color="primary"
              @click="e1 = 2">
              السابق
            </v-btn>

            
              </v-flex>-->

              <v-flex xs3 pr-3 v-if="ItemFeauterAvailable">

                <v-btn style="position:relative;right:3%" color="primary" @click="e1 = 4">
                  السابق
                </v-btn>


              </v-flex>

              <v-flex xs3 v-else>
                <v-btn style="position:relative;right:3%" color="primary" @click="pay_prev()">
                  السابق
                </v-btn>



              </v-flex>
            </v-layout>

          </v-stepper-content>

          <v-stepper-content step="5" v-if="!moreItem">

            <!-- <v-btn color="primary" style="position: relative;right: 3%;width: 103px;" @click="e1 = 1">


              Continue
            </v-btn>

            <v-btn text @click="close()">خروج</v-btn> -->
          </v-stepper-content>
          <!--End Reservation Payment-->

        </v-stepper-items>
      </v-stepper>


    </v-dialog>
  </div>
</template>


<script>
  const axios = require('axios');
  import moment from 'moment/src/moment'
  // import moment from 'moment';
  //import BookingDetails from './BookingDetails.vue'
  import {
    EventBus
  } from '../event-bus.js';
  export default {




    data: () => ({
      BookingDetailsInfo: {},
      model: 0,
      valid_SelectItem: false,
      time_av: false,
      withoutBills:false,
      day_to_open: [{
        every_day: '',
      }],
      moreItem: true,
      // nameRules: [
      //   v => !!v || 'يجب ادخال الاسم',
      // ],
      xx: true,
      phoneRules: [
        (v) => !!v || 'يجب ادخال رقم الهاتف',
        (v) => v.length > 10 && v.length < 12 || 'يجب ادخال رقم هاتف صحيح',
        (v) => /^\d+$/.test(v) || 'يجب ادخال رقم هاتف صحيح',

      ],
      ItemsReservationRequirements: [],
      pricepayment_when_receiving: '',
      possib_reserving_period: '',
      e1: '',
      items: [],
      BookingDetails: true,
      rating: 4,
      fawater_res_Dailog: false,
      rating_dailog: false,
      reservations_number: null,
      valid_day: true,
      valid_pay: true,
      date: '',
      date_to: '',
      sub_time_id: '',
      owner_itemId: '',
      img_click: '',
      ItemFeauter: [],
      ItemFeauterAvailable: false,
      itemid: '',
      errors: [],
      images: ['ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab', 'ab'],
      img_name: '',
      miniDate: new Date().toISOString().substr(0, 10),
      time: '',
      dayselectid: 0,
      dayoffer: [],
      date_dialog: false,
      date_to_dialog: false,
      time_dialog: false,
      booking_dialog: false,
      subs_time: {

      },
      address: '',
      ItemFeauterSelected: [],
      aa: [],
      allowedHour: [],
      fatwra_dialog: false,
      phoneReq: false,
      loading: false,
      bill: true,
      reserv_count: '',
      available_times: [],
      req_id: '',
      ReservationRequirements: [],
      color: 'color:green',



      numRules: [

        (v) => !!v || 'يجب ادخال رقم الهاتف',
        (v) => v.length > 10 && v.length < 12 || 'يجب ادخال رقم هاتف صحيح',
      ],
      DatenameRules: [
        v => !!v || 'هذا الحقل مطلوب  ',
      ],
      UsernameRules: [
        v => !!v || 'هذا الحقل مطلوب  ',
      ],
      ItemnameRules: [
        v => !!v || 'هذا الحقل مطلوب  ',
      ],



      TimeRules: [
        v => !!v || 'هذا الحقل مطلوب  ',
      ],


      id: '',
      Owner_items: [],

      reserv_date: [],
      post_data: {

        item_id: "",
        user: {
          name: '',
          phone: ''
        },
        reservation_start_date: "",
        reservation_date: "",
        item_features: [],
        withoutBills:0,
        reservation_end_date: "",
        reservation_from_time: "",
        reservation_to_time: "",
        reservation_number: "1",
        deliverable: false,
        phone: "",
        ReservationRequirements: [],
        sub_time_id: ""



      },


      ave_data: {
        item_id: '',
        date: ''
      }

    }),
    mounted() {
      this.getitems();

      this.getOwnerItems();



    },

    created() {
      /// EventBus.$emit('getUserReservationsCount', true);



    },


    methods: {
      pay_prev() {
        if (this.ItemFeauterAvailable) {
          this.e1 = 4
        } else if (this.day_to_open[0].every_day == 0) {
          this.e1 = 2;

        } else if (this.day_to_open[0].every_day == 1) {
          this.e1 = 1;

        }

      },

      step1() {
        this.moreItem = false;

        if (this.$refs.valid_UserInfo.validate()) {

          if (this.day_to_open[0].every_day == 1) {
            if (this.ItemFeauterAvailable) {
              this.e1 = 4
            } else if (!this.ItemFeauterAvailable) {
              this.e1 = 5


            }

          } else {
            this.e1 = 2
          }

        }
      },

      getOwnerItems() {

        //var url = "/v2/items/search?filter[owner.user_id]=" + this.$cookies.get('owner_info').id;
        var url= "/v2/items/owner/get?page=1";
        this.loading = true
        this.$http({
          method: 'get',
          url: url,
          headers: {

          }

        }).then(response => {
          this.Owner_items = response.data.data;
          this.last_page = response.data.meta.last_page;
          this.pageCount = response.data.meta.last_page;


        }).catch(error => {

          error


        }).finally(d => {
          d,
          this.loading = false;
        });

      },






//var url = "/v2/items/search?filter[owner.user_id]=" + this.$cookies.get('owner_info').id;
      getitems() {


        this.loading = true
        this.$http({
          method: 'get',
          url: "/v2/items/owner/get?page=" + this.current_page,
          headers: {

          }

        }).then(response => {

          this.items = response.data.data;


          if (this.items.length > 1) {
            this.e1 = 0;
            this.moreItem = true;

          } else

          {
            this.e1 = 1;

            this.itemid = this.items[0].id;
            this.day_to_open = this.items[0].day_to_open;
            this.ItemFeauter = this.items[0].ItemFeatures;
            this.ItemsReservationRequirements = this.items[0].ItemsReservationRequirements;
            this.pricepayment_when_receiving = this.items[0].price.payment_when_receiving;
            this.possib_reserving_period = this.items[0].possib_reserving_period;


            if (this.items[0].ItemFeatures.length > 0) {
              this.ItemFeauterAvailable = true;

            }
          }




        }).catch(error => {
          error

        }).finally(d => {
          d,
          this.loading = false;
        });

      },


      getitemsById() {


        this.loading = true
        this.$http({
          method: 'get',
          url: "/v2/items/show/" + this.owner_itemId,
          headers: {

          }

        }).then(response => {


          this.items = response.data.data;

          this.itemid = this.items.id;
          this.day_to_open = this.items.day_to_open;
          console.log(this.day_to_open);

          this.ItemFeauter = this.items.ItemFeatures;

          this.ItemsReservationRequirements = this.items.ItemsReservationRequirements;
          //this.moreItem=false;

          this.pricepayment_when_receiving = this.items.price.payment_when_receiving;
          this.possib_reserving_period = this.items.possib_reserving_period;


          if (this.items.ItemFeatures.length > 0) {
            this.ItemFeauterAvailable = true;

          }






        }).catch(error => {
          if (error.response.status == 402) {
            this.$swal(" انتهت الجلسة", "يجب تسجيل الدخول", 'error')
            this.logout();

          } else {
            // this.$swal('خطاء', "خطاء بالاتصال", 'error')
          }

        }).finally(d => {
          d,
          this.loading = false;
        });

      },


      check_re() {
        if (this.ItemsReservationRequirements.length !== this.ReservationRequirements.length) {
          alert('يجب رفع جميع الصور المرفقه');
          this.e1 = 2;
        }
      },

      Select_Items() {
        if (this.$refs.valid_SelectItem.validate()) {
          this.e1 = 1;

        }

      },
      booking_item() {



        //this.post_data.item_feathures.push(this.post_data.item_feathures);


        if (!this.$refs.form_fa.validate() && this.pricepayment_when_receiving == 0) {

          this.bil

        } else {
          
          if(!this.withoutBills)
          {
            this.post_data.withoutBills=1;
          }
          else if(this.withoutBills)
          {
            
            this.post_data.withoutBills=0;
           

          }
          this.post_data.user.phone = "964" + parseInt(this.post_data.user.phone);

          if (this.day_to_open[0].every_day == 0 && this.$refs.form.validate()) {
            this.post_data.sub_time_id = this.subs_time.id;
            this.post_data.reservation_from_time = this.subs_time.from_sub_time;
            this.post_data.reservation_to_time = this.subs_time.to_sub_time;
            this.post_data.item_id = this.itemid;
            this.post_data.ReservationRequirements = this.ReservationRequirements;
            this.post_data.reservation_date = this.date;
            this.post_data.reservation_start_date = this.date;

            if (this.possib_reserving_period == 1) {

              this.post_data.reservation_end_date = this.date_to;
            } else {
              this.post_data.reservation_end_date = this.date;

            }


          } else if (this.day_to_open[0].every_day == 1) {


            this.post_data.reservation_date = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
            this.post_data.reservation_start_date = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
            this.post_data.reservation_end_date = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
            this.post_data.ReservationRequirements = this.ReservationRequirements;
            this.post_data.reservation_from_time = new Date().toLocaleTimeString('en-US', {
              hour12: false,
              hour: "numeric",
              minute: "numeric"
            });
            this.post_data.reservation_to_time = new Date().toLocaleTimeString('en-US', {
              hour12: false,
              hour: "numeric",
              minute: "numeric"
            });
            this.post_data.item_id = this.itemid;

          }

          this.loading = true;




          this.$http({
              method: 'post',
              url: "/reservation/owner/set",
              data: this.post_data,
              headers: {

              }
            })
            .then(response => {
              this.BookingDetails = false;
              //  this.$emit("changeStatus", false);
              this.$swal('', "    تم  الحجز بنجاح", 'success')
              EventBus.$emit('GetRes', true)
              this.$refs.form.reset()
              //    this.e1 = 1;
              if ("payId" in response.data) {
                this.fatwra_num = response.data.data.payId;
                this.$refs.form_fa.reset()
                this.fawater_res_Dailog = true;
              }
              // this.rating_dailog = true;



              //bookink item succesful

            })

         
            
              .catch(error => {
                                this.booking_dialog = false;
                                    if (error.response) {
                                        if(error.response.data.data=='SubscriptionsPackages Expire')
                                        {
                                             this.$swal('', "حزمه الاشتراك الحاليه منتهيه يرجئ شراء حزمه جديده ", 'error')

                                        }
                                       

                                    }
                                }

                            )
            
            .finally(d => {
              d;
              this.booking_dialog = false;
              this.loading = false;
            });


        }


        // if (this.info.item_price.book_without_credit_card == 0 && this.post_data.phone.length < 11) {
        //   //this.$swal('', "    تم  الحجز بنجاح", 'success')
        //   this.fatwra_dialog = true;
        //   return;
        // }



      },

      date_today() {
        var x = new Date();
        return moment(String(x)).format('YYYY-MM-DD');

      },
      ava_sub(subs_time) {
        for (var i = 0; i < subs_time.length; i++) {
          if (subs_time[i].reservation.length == 0) {

            return subs_time[i];
          }
        }
      },


      count_availble_per(subs_time) {
        subs_time
        // var x;

        var peroid_time = 0;

        for (var i = 0; i < subs_time.length; i++) {

          if (subs_time[i].reservation.length == 0) {
            peroid_time = peroid_time + 1;


          }

          //x = peroid_time;
        }
        return peroid_time;

      },
      get_times() {

        this.ave_data.item_id = this.itemid;
        this.ave_data.date = this.date;


        this.$http({
            method: 'post',
            url: "/v2/reservation/GetAvilableReservationPeriods",
            data: this.ave_data

          })


          .then(response => {
            this.available_times = response.data.data;
            if (this.available_times.length == 0) {
              // alert('لاتوجد اوقات متاحه في هاذه الفتره');
            }



          })

      },

      formatAMPM(date) {
        if (typeof date === "string") {
          let [hours, minutes] = date.split(":");
          let ampm = "ص";

          if (Number(hours) > 12) {
            hours = Number(hours) - 12;
            ampm = "م";
          }

          return `${hours}:${minutes} ${ampm}`;

        } else if (date instanceof Date) {
          let hours = date.getHours();
          let minutes = date.getMinutes();

          let ampm = hours >= 12 ? 'م' : "AM";

          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? "0" + minutes : minutes;

          let strTime = hours + ":" + minutes + " " + ampm;

          return strTime;
        }

        return date;
      },
      close() {
        this.BookingDetails = false;
        EventBus.$emit('GetResCancel', true)
        this.date_dialog = false;
        this.$refs.form_fa.reset()
        this.e1 = 1;
        this.$emit("changeStatus", false);



      },

      step2() {



        this.errors = [];
        if (!this.date) {
          this.$refs.form.validate();

        } else if (this.subs_time.id === undefined) {
          this.$swal('  خطاء', "يرجئ اختيار وقت الحجز", 'error')

        } else {
          // if (this.ItemsReservationRequirements.length > 0) {
          //   this.e1 = 3;
          // } 

          if (this.ItemFeauterAvailable) {
            this.e1 = 4;
          } else if (this.pricepayment_when_receiving == 0) {
            this.e1 = 5;
          }

        }


      },

      stepForItemReq() {


        if (this.ItemsReservationRequirements.length !== this.ReservationRequirements.length) {
          this.e1 = 2;
        } else {
          this.e1 = this.pricepayment_when_receiving == 0 ? 4 : 2;
        }






      },




      delete_img(img_id, index) {





        const Swal = require('sweetalert2');



        Swal.fire({
          title: "هل انت متاكد من الحذف ؟",

          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'

        }).then((result) => {
          if (result.value) {
            this.img_cliced = index;


            this.images[index] = 'ab';
            this.img_name = 'ghjk'


          }
        })




      },


      cancelImg() {
        this.images[0] = 'ab';
        this.img_name = 'ghjk'

      },
      pickFile(id, index) {
        this.req_id = id;
        this.img_click = index;
        this.$refs.image.click();
      },

      onFilePicked(e) {


        const files = e.target.files
        if (files[0] !== undefined) {
          this.imageName = files[0].name
          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.imageUrl = fr.result
            this.imageFile = files[0]


            // this.images = [];
            var req = {
              item_reservation_requirements_id: this.req_id,
              images: [fr.result]

            }

            this.ReservationRequirements.push(req);
            this.images[this.img_click] = fr.result;

            // this.imgname = files[0].name;
            var a = this.img_click + 1;
            this.img_name = "   صورة رقم " + a



          })

        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
        }
      },
      // selectAvatar(avatar) {
      //     this.form.avatar = avatar
      // }
    },


    validate_fawater() {
      if (this.$refs.form_fa.validate()) {
        this.fatwra_dialog = false;
      }
    },





    getDate_andTime(id) {

      var url = this.urL + "/getDaysTime/" + id;
      axios.get(url).then(response => {
        this.day_to_open = response.data.Data;

        for (var day = 0; day < 7; day++) {

          var offer = '';
          for (var i = 0; i < this.dayoffer.length; i++) {

            if (this.dayoffer[i] == day) {

              offer = 1;

            }



          }

          this.sechdual_info.push({
            day_name: this.getdays(day + 1),
            time_duration: ['غير متوفر'],
            status: false,
            offer: offer



          });
          for (i = 0; i < this.day_to_open.length; i++) {
            if (day >= this.day_to_open[i].from_day && day <= this.day_to_open[i].to_day) {

              var t = [];

              for (var time = 0; time < this.day_to_open[i].time_to_open.length; time++) {


                var from_time = this.day_to_open[i].time_to_open[time].from_time.split(":");
                var totime = this.day_to_open[i].time_to_open[time].to_time.split(":");



                t.push(from_time[0] + ":" + from_time[1] + " - " +
                  totime[0] + ":" + totime[1])

              }

              this.sechdual_info[day].time_duration = t
              this.sechdual_info[day].status = true



              this.timeline_status[day] = true;

            }
          }



        }



      })

    },
    getReserDate(from, to, time) {


      var url = '/reservation/SearchAvilableReservation?filter[item_id]=' + this.itemid +
        '&filter[BetweenTime]=' + time + '&&filter[BetweenDate]=' + from +
        '_' + to + '&page=' + 1;


      this.$http({
          method: 'get',
          url: url,
          data: this.post_data,
          headers: {

          }
        })

        .then(response => {

          this.reserv_date = response.data.data;
          this.reserv_count = response.data.meta.count;
          for (var i = 0; i < this.reserv_date.length; i++) {
            // var hours = this.reserv_date[i].reservation_from_time.split(":");
            //var hour = parseInt(hours[0]);
            time = this.time.split(":");


            time = parseInt(time);



            const index = this.allowedHour.indexOf(time);

            if (index > -1) {
              this.allowedHour.splice(index, 1);
            }






            if (this.reservations_number !== null) {

              if (this.reserv_count > this.reservations_number) {
                this.$swal('', " عذرا لاتوجد اوقات حجز متاحه في هاذا اليوم العدد ممتلئ", 'error')
                this.time = '';


              }


            } else if (!this.allowedHour.includes(time)) {
              this.$swal('', " عذرا لاتوجد اوقات حجز متاحه في هاذا الوقت", 'error')
              this.time = '';

            } else if (this.allowedHour.length == 0) {


              this.$swal('', " عذرا لاتوجد اوقات حجز متاحه في هاذا اليوم", 'error')
              this.time = '';

            } else if (this.allowedHour == null) {
              this.$swal('', " عذرا لاتوجد اوقات حجز متاحه في هاذا اليوم", 'error')
              this.time = '';

            }


          }

        });

    },
    allowedDates(val) {

      var d = new Date(val);

      for (var i = 0; i <= 6; i++) {
        if (d.getDay() == this.day_to_open[i].work_day) {

          if (this.day_to_open[i].status.id == 23) {
            return true;
          } else {
            return false;
          }
        }
      }

    },




    allowedHours(v) {
      v
    },

    allowedMinutes(v) {
      v
      return true;

    },

    select_to_date() {
      this.date_to_dialog = false;
      //http://109.224.27.9:81/api/v2/reservation/GetAvilableReservationPeriods
      // if (this.possib_reserving_period == 1) {

      //   this.post_data.reservation_end_date = this.date_to;
      // } else {
      //   this.post_data.reservation_end_date = this.date;

      // }

    },

    select_date() {
      // alert('xx');
      // alert(this.date);
      //this.date_dialog = false;
      this.post_data.reservation_start_date = this.date;

      this.post_data.reservation_date = this.date;
      if (this.possib_reserving_period == 1) {

        this.post_data.reservation_end_date = this.date_to;
      } else {
        this.post_data.reservation_end_date = this.date;

      }


      var d = new Date(this.date);

      this.allowedHour = [];
      for (var i = 0; i < 6; i++) {


        if (d.getDay() == this.day_to_open[i].work_day) {
          if (this.day_to_open[i].status.id == 23) {
            for (var j = 0; j < this.day_to_open[i].time_to_open.length; j++) {
              var hours1 = this.day_to_open[i].time_to_open[j].from_time.split(":");
              hours1 = parseInt(hours1[0]);
              var hours2 = this.day_to_open[i].time_to_open[j].to_time.split(":");
              hours2 = parseInt(hours2[0]);

              for (var k = hours1; k < hours2; k++) {
                this.allowedHour.push(k);
              }

            }
          }
        }



      }

    },



    select_time() {


      this.time_dialog = false;

      var d = new Date(this.date);
      for (var i = 0; i < 6; i++) {


        if (d.getDay() == this.day_to_open[i].work_day) {
          if (this.day_to_open[i].status.id == 23) {
            for (var j = 0; j < this.day_to_open[i].time_to_open.length; j++) {
              //  if(this.time>)

              var time = this.time.split(":");


              time = parseInt(time);

              var hours1 = this.day_to_open[i].time_to_open[j].from_time.split(":");
              hours1 = parseInt(hours1[0]);

              var hours2 = this.day_to_open[i].time_to_open[j].to_time.split(":");
              hours2 = parseInt(hours2[0]);

              if (time >= hours1 && time <= hours2) {
                if (this.day_to_open[i].time_to_open[j].reservation_type !== null)


                {
                  if (this.day_to_open[i].time_to_open[j].reservation_type.id == 2) {
                    this.reservations_number = this.day_to_open[i].time_to_open[j].reservations_number;
                    // alert(this.day_to_open[i].time_to_open[j].reservations_number);
                  } else {
                    this.reservations_number == null;

                  }
                } else {
                  this.reservations_number == null;

                }


              }
            }

          }
        }
      }

      this.post_data.reservation_from_time = this.time
      this.post_data.reservation_to_time = this.time

      this.getReserDate(this.date, this.date, this.time);



    },



    getPath(img_path) {

      return this.urL + "/" + img_path;

    },


















    getdays(index) {



      var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];

      return days[index];

    },

    components: {
      // BookingDetails,
    }



  }
</script>